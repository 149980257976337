<template>
  <div class="vca-column">
    <vca-accordion :header="$t('faqs.festivals.festival.header')">
      <div slot="body" v-html="$t('faqs.festivals.festival.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.festivals.band.header')">
      <div slot="body" v-html="$t('faqs.festivals.band.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.festivals.age.header')">
      <div slot="body" v-html="$t('faqs.festivals.age.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.festivals.nofestival.header')">
      <div slot="body" v-html="$t('faqs.festivals.nofestival.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.festivals.feedback.header')">
      <div slot="body" v-html="$t('faqs.festivals.feedback.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.festivals.get_there.header')">
      <div slot="body" v-html="$t('faqs.festivals.get_there.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.festivals.stuff.header')">
      <div slot="body" v-html="$t('faqs.festivals.stuff.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.festivals.sleeping.header')">
      <div slot="body" v-html="$t('faqs.festivals.sleeping.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.festivals.friends.header')">
      <div slot="body" v-html="$t('faqs.festivals.friends.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.festivals.alcohol.header')">
      <div slot="body" v-html="$t('faqs.festivals.alcohol.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.festivals.other_times.header')">
      <div slot="body" v-html="$t('faqs.festivals.other_times.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.festivals.ticket.header')">
      <div slot="body" v-html="$t('faqs.festivals.ticket.body')" />
    </vca-accordion>
  </div>
</template>
<script>
export default {
  name: "FaqsFestivals",
};
</script>
